
body {
  /*background-image: linear-gradient(to bottom right, #de8b8b, #eeee1d);*/
  /*height: 100%;*/
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.header {
  text-align: center;
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.music-player {
  margin: 8px 8px 3px 8px;
  box-shadow: 0.3em 0.3em 1em rgba(0,0,0,0.3);
}

.minimal-nav ul {
  list-style-type: none;
  padding-inline-start: 0;
  color: #00000088;
}

.minimal-nav ul li {
  display: inline;
  background-color: #fff2;
}
.minimal-nav ul li:first-child {
  padding-left: 10px;
}
.minimal-nav ul li:last-child {
  padding-right: 10px;
}

.minimal-nav ul li:after {
  padding-left: 6px;
  padding-right: 6px;
  content: "|";
  color: #00000022;
}

.minimal-nav ul li:last-child:after {
  content: none;
}

.minimal-nav ul li span.selected {
  border-bottom: 1px solid #00000044;
}


.bio-avatar {
  border: 6px solid #0004;
  border-radius: 50%;
  max-width: 100%;
  box-shadow: 0.3em 0.3em 1em rgba(0,0,0,0.3);
}

.page-content {
  padding-left: 50px !important;
  padding-right: 50px  !important;
}

.pane {
  border-radius: 15px;
  padding: 20px;
  background-color: #fff2;
}

canvas.carpet {
  border: 1px dashed yellow;
  margin-left: auto;
  margin-right: auto;
}