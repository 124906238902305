.art-wall-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
}

/* Create four equal columns that sits next to each other */
.art-wall-column {
  flex: 25%;
  max-width: 25%;
  padding: 0 8px;
}

.art-wall-column img {
  margin-top: 16px;
  vertical-align: middle;
  width: 100%;
  box-shadow: 0.3em 0.3em 1em rgba(0,0,0,0.3);
}

/* Responsive layout - makes a two columrn-layout instead of four columns */
@media screen and (max-width: 800px) {
  .art-wall-column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}