.contactForm {
  max-width: 400px;
  margin: 0 auto;
  .instructions,.feedback {
    text-align: center;
    padding: 10px;
    color: #0009;
  }
  input, textarea {
    padding: 4px 6px;
    border-radius: 5px;
  }
  input, textarea, button {
    width: 100%;
    margin-bottom: 2px;
  }
  button {
    background-color: #fdfaff;
  }
}
.links {
  text-align: center;
}
.links a{
  margin-right: 4px;
}
